import React from 'react';
import cn from 'classnames';
import styles from './Spinner.module.scss';
import loader from './spinner.gif'

interface SpinnerProps {
  width?: number;
  className?: string;
}

function Spinner({ width = 45, className }: SpinnerProps) {
  return (
    <div className={cn(styles.wrapper, className)}>
      <img src={loader} width={width} />
    </div>
  );
}

export default Spinner;
