import React, { FC, useMemo } from 'react';
import logo from './images/logo.png';

interface LogoProps {
  height?: number;
  className?: string;
}

const Logo: FC<LogoProps> = ({ height, className }) => {
  const style = useMemo(
    () => ({
      height,
    }),
    [height]
  );
  return <img src={logo} style={style} className={className} />;
};

export default Logo;
