import * as React from 'react';
import cn from 'classnames';
import styles from './Text.module.scss';

export interface TextProps {
  children: React.ReactNode;
  weight?: 'thin' | 'normal' | 'bold';
  align?: 'left' | 'center' | 'right';
  valign?: 'top' | 'middle' | 'bottom' | 'baseline';
  decoration?: 'overline' | 'lineThrough' | 'underline' | 'noDecoration';
  fontStyle?: 'normal' | 'italic';
  color?: 'dark' | 'standard' | 'light' | 'primary' | 'secondary' | 'neutral';
  transform?: 'uppercase' | 'lowercase' | 'capitalize';
  lineHeight?: string;
  fontSize?: string;
  className?: string;
  block?: boolean;
  alternativeFontFamily?: boolean;
  style?: object;
}

const Text = ({
  children,
  className,
  block = false,
  weight = 'normal',
  align,
  decoration = 'noDecoration',
  transform,
  fontStyle = 'normal',
  fontSize = '1rem',
  color = 'dark',
  valign = 'baseline',
  lineHeight,
  alternativeFontFamily,
  style
}: TextProps) => {
  const elemProps = {
    className: cn(
      styles.text,
      styles[weight],
      styles[align],
      styles[decoration],
      styles[fontStyle],
      styles[color],
      styles[valign],
      styles[transform],
      { [styles.alternativeFontFamily]: alternativeFontFamily },
      className
    ),
    style: { fontSize, lineHeight, ...style },
  };
  return block ? (
    <p {...elemProps}>{children}</p>
  ) : (
    <span {...elemProps}>{children}</span>
  );
};

export default Text;
