import React, { useState, useCallback } from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import styles from './Nav.module.scss';
import { Icon } from 'semantic-ui-react';
import { ROUTES } from 'types/routes';

const NavSmall = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleMenu = useCallback(() => setIsCollapsed(!isCollapsed), [
    isCollapsed,
  ]);

  return (
    <>
      {isCollapsed && (
        <Icon
          name="sidebar"
          size="large"
          className={styles.hamburgerIcon}
          onClick={toggleMenu}
        />
      )}
      <nav className={cn(styles.navSmall, { [styles.expanded]: !isCollapsed })}>
        <Icon
          name="chevron up"
          size="large"
          className={styles.chevronIcon}
          onClick={toggleMenu}
        />
        <Link
          to="/"
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          Start
        </Link>
        <Link
          to={ROUTES.ABOUT}
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          O nas
        </Link>
        <Link
          to={ROUTES.SPECIALIZATIONS}
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          Oferta
        </Link>
        <Link
          to={ROUTES.TEAM}
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          Zespół
        </Link>
        <Link
          to={ROUTES.NEWS}
          className={styles.link}
          activeClassName={styles.activeLink}
          partiallyActive
        >
          Aktualności
        </Link>
        <Link
          to={ROUTES.CONTACT}
          className={styles.link}
          activeClassName={styles.activeLink}
        >
          Kontakt
        </Link>
      </nav>
    </>
  );
};

export default NavSmall;
