import React, { FC } from 'react';
import cn from 'classnames';
import Text, { TextProps } from '../Text/Text';
import styles from './SectionHeading.module.scss';
import Gap from '../Gap/Gap';

interface SectionHeadingProps {
  smallHeading?: string;
  largeHeading: string;
  color?: TextProps['color'];
  centered?: boolean;
  className?: string;
  inline?: boolean;
  withUnderline?: boolean;
}

const SectionHeading: FC<SectionHeadingProps> = props => (
  <div
    className={cn(
      { [styles.centered]: props.centered },
      { [styles.inline]: props.inline },
      styles.wrapper,
      props.className
    )}
  >
    <h3 className={styles.smallHeading}>
      <Text color={props.color} fontSize=".8rem">
        {props.smallHeading && <p>{props.smallHeading}</p>}
      </Text>
    </h3>
    <Gap top={props.smallHeading ? "md" : undefined}>
      <h2 className={styles.largeHeading}>
        <Text color={props.color} fontSize="2.8rem" weight="bold">
          <p>{props.largeHeading}</p>
          {props.withUnderline && <div className={styles.line} />}
        </Text>
      </h2>
    </Gap>
  </div>
);

SectionHeading.defaultProps = {
  withUnderline: true,
};

export default SectionHeading;
