import React, { FC } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet';
import { Grid } from 'react-flexbox-grid';
import styles from './Layout.module.scss';
import Contact from '../Contact/Contact';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => (
  <Grid fluid className={styles.grid}>
    <Helmet>
      <link
        rel="stylesheet prefetch"
        href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/components/icon.min.css"
      />
      <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      <meta charSet="UTF-8" />
      <meta
        name="keywords"
        content="prawo, kancelaria, pwkp, puchała, wróbel, krupa, polifke, obsługa prawna, pomoc prawna, adwokat, gliwice"
      />
      <meta
        name="description"
        content="Kancelaria Radców Prawnych PWKP - Partnerzy Wróbel Krupa Polifke. Obsługa prawna przedsiębiorców i jednostek samorządu terytorialnego."
      />
      <title>PWKP</title>
    </Helmet>
    <div className={styles.layout}>
      {children}
      <footer className={styles.footer}>
        <Contact />
      </footer>
    </div>
  </Grid>
);

export default Layout;
