import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { Row, Col } from 'react-flexbox-grid';
import styles from './Header.module.scss';
import Logo from '../Logo/Logo';
import Gap from '../Gap/Gap';
import Nav from './Nav/Nav';
import NavSmall from './Nav/NavSmall';

interface HeaderProps {
  withUnderlines?: boolean;
  withBackground?: boolean;
  block?: boolean;
  logoHeight?: number;
  logoClassName?: string;
}

const Header: FC<HeaderProps> = props => {
  const [smallNav, setSmallNav] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 1150) {
      setSmallNav(true);
    }
  }, []);

  return (
    <header
      className={cn(styles.header, {
        [styles.withBackground]: props.withBackground,
        [styles.block]: props.block,
      })}
    >
      <Row between="xs" middle="xs">
        <Col xs={1}>
          <Gap top="md" bottom="md">
            <Logo
              height={props.logoHeight}
              className={cn(styles.logo, props.logoClassName)}
            />
          </Gap>
        </Col>
        <Col xs={11}>
          <Row end="xs">
            {smallNav ? (
              <NavSmall />
            ) : (
              <Nav withUnderlines={props.withUnderlines} />
            )}
          </Row>
        </Col>
      </Row>
    </header>
  );
};

export default Header;
