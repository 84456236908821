import React, { FC } from 'react';
import { Link } from 'gatsby';
import styles from './Nav.module.scss';
import { ROUTES } from 'types/routes';

interface NavProps {
  withUnderlines?: boolean;
}

const Nav: FC<NavProps> = props => (
  <nav className={styles.nav}>
    <Link to="/" className={styles.link} activeClassName={styles.activeLink}>
      Start
    </Link>
    <Link
      to={ROUTES.ABOUT}
      className={styles.link}
      activeClassName={styles.activeLink}
      partiallyActive
    >
      O nas
    </Link>
    <Link
      to={ROUTES.SPECIALIZATIONS}
      className={styles.link}
      activeClassName={styles.activeLink}
      partiallyActive
    >
      Oferta
    </Link>
    <Link
      to={ROUTES.TEAM}
      className={styles.link}
      activeClassName={styles.activeLink}
      partiallyActive
    >
      Zespół
    </Link>
    <Link
      to={ROUTES.NEWS}
      className={styles.link}
      activeClassName={styles.activeLink}
      partiallyActive
    >
      Aktualności
    </Link>
    <Link
      to={ROUTES.CONTACT}
      className={styles.link}
      activeClassName={styles.activeLink}
      partiallyActive
    >
      Kontakt
    </Link>
    {props.withUnderlines && (
      <>
        <div className={styles.underline1} />
        <div className={styles.underline2} />
      </>
    )}
  </nav>
);

export default Nav;
