import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { Row, Col } from 'react-flexbox-grid';
import { Icon } from 'semantic-ui-react';
import Logo from '../Logo/Logo';
import styles from './Contact.module.scss';
import Gap from '../Gap/Gap';
import Text from '../Text/Text';
import { useStaticQuery, graphql } from 'gatsby';
import { getPrivatePolicyUrl } from 'services/uploaded-files';

const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          contact {
            tel
            fax
            email
            fbLink
            twitterLink
            linkedInLink
            name1
            name2
            name3
            address
          }
        }
      }
    }
  }
`;

const Contact: FC<{}> = () => {
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState<string>();
  const data = useStaticQuery(query);
  const { contact } = data.allDataJson.edges[0].node;

  const fetchPrivacyPolicyUrl = async () => {
    const url = await getPrivatePolicyUrl();
    setPrivacyPolicyUrl(url);
  };

  useEffect(() => {
    fetchPrivacyPolicyUrl();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.back} />
      <div className={cn(styles.section)}>
        <Logo className={styles.logo} />
      </div>
      <div className={cn(styles.section, styles.pwkp)}>
        <Text color="light" weight="bold" block>
          {contact.name1}
        </Text>
        <Text color="standard" block>
          {contact.name2}
        </Text>
        <Text color="standard" block>
          {contact.name3}
        </Text>
      </div>
      <div className={cn(styles.section, styles.contact)}>
        <h3>
          <Text color="light" weight="bold" block>
            KONTAKT
          </Text>
        </h3>
        <Text color="standard" block>
          Adres: {contact.address}
        </Text>
        <Text color="standard" block>
          Tel: {contact.tel}
        </Text>
        <Text color="standard" block>
          Email: {contact.email}
        </Text>
      </div>
      <div className={cn(styles.section, styles.social)}>
        <h3>
          <Text color="light" weight="bold" block>
            SOCIAL MEDIA
          </Text>
        </h3>
        <Row>
          <Gap left="xs" className={styles.social}>
            <a href="#">
              <Icon name="facebook official" size="large" />
            </a>
            <a href="#">
              <Icon name="linkedin square" size="large" />
            </a>
          </Gap>
        </Row>
        <Gap top="md">
          <Text color="light" weight="bold" block>
            <a href={privacyPolicyUrl} target="_blank">
              Polityka prywatności{' '}
              <Gap left="xs" isBlock={false}>
                <Icon name="external alternate" />
              </Gap>
            </a>
          </Text>
        </Gap>
      </div>
    </div>
  );
};

export default Contact;
