import axios from 'axios';
import config from '../config';

export const getPrivatePolicyUrl = async () =>
  axios.get(`${config.apiUrl}/files`).then(({ data }) => {
    const file = data.find(
      (file: { name: string; file: { url: string } }) =>
        file.name === 'polityka-prywatnosci'
    );

    return file ? file.file.url : undefined;
  });
