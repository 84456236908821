import React from 'react';
import cn from 'classnames';
import styles from './Gap.module.scss';

type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

interface GapProps {
  top?: Size;
  bottom?: Size;
  left?: Size;
  right?: Size;
  all?: Size;
  className?: string;
  isBlock?: boolean;
  children: React.ReactNode;
}

const Gap = ({
  top,
  bottom,
  left,
  right,
  all,
  className,
  isBlock = true,
  children,
}: GapProps) => {
  const gapClasses = all
    ? cn(
        styles.gap,
        // @ts-ignore
        styles[`all${all}`],
        { [styles.block]: isBlock },
        className
      )
    : cn(
        styles.gap,
        {
          // @ts-ignore
          [styles[`top${top}`]]: top,
          // @ts-ignore
          [styles[`bottom${bottom}`]]: bottom,
          // @ts-ignore
          [styles[`left${left}`]]: left,
          // @ts-ignore
          [styles[`right${right}`]]: right,
          [styles.block]: isBlock,
        },
        className
      );
  return <div className={gapClasses}>{children}</div>;
};

export default Gap;
